.container {
  background-color: #c5e3e0;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 1252px;
  margin: 20px auto;
  min-height: 410px;
}
.title {
  text-align: center;
  font-size: 2.5em;
  font-weight: 850;
  font-family: sans-serif;
}
.description {
  font-size: 1.64em;
  line-height: 1.7;
  color: #333;
  font-weight: 500;
  text-align: justify;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 15px;
}
