.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #d6f7ef;
  margin-top: 35px;
  padding-bottom: 30px;
}

.contact-title {
  font-size: 2.6em;
  font-weight: 900;
  margin-bottom: 10px;
  color: #333;
  margin-top: 0px;
}

.contact-description {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
  color: #666;
}

.desc {
  color: #00b3b6;
  font-size: 1.44em;
  text-align: center;
  justify-content: center;
  margin-left: 90px;
  margin-right: 85px;
  max-width: 990px;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 957px;
  background-color: black;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 410px;
}

.badlo {
  border-radius: 50px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 40px;
}

.contact-info p {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 1rem;
  color: white;
  padding: 2px;
}

.contact-info p p {
  margin-left: 8px;
}

.contact-info p a {
  text-decoration: none;
  color: white;
  margin-left: 8px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.contact-info p a:hover {
  color: #00b3b6;
  transform: scale(1.1);
}

.contact-info a:hover .contact-icon {
  transform: scale(1.2);
}

.contact-info a:hover span {
  text-decoration: underline;
}

.contact-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.contact-form {
  flex: 1;
  background-color: white;
  max-width: 600px;
  margin-right: 20px;
  padding: 18px;
  border-radius: 13px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 15px;
}

.form-gp {
  width: 70%;
  border: 0px;
  padding: 2px;
  margin-bottom: 20px;
  border-radius: 4px;
  outline: none;
  display: flex;
  font-size: 1rem;
  gap: 63px;
  margin-top: 15px;
}

.form-input {
  width: 85%;
  padding: 10px;
  margin-bottom: 20px;
  border: 0px;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
}

.form-fa {
  border: 0px;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  margin-left: 2px;
  padding-bottom: 10px;
}

.form-la {
  border: 0px;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  padding-bottom: 10px;
}

.form-button {
  padding: 10px 20px;
  background-color: #1cbe92;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  margin-left: 455px;
  margin-top: 30px;
}

.form-button:hover {
  background-color: #139c79;
}

